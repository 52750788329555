import { APIData } from "api/types";
import {Contract, CreativeGroupV2, ID, NEW_CreativeGroup} from "models";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { CheckBoxField } from "ui/CheckBox";
import { PredictiveSearchField } from "ui/PredictiveSearch";
import { searchMutationContract } from "ui/PredictiveSearch/helpers";
import getErrorText from "utils/getErrorText";
import shortTimeout from "utils/shortTimeout";
import {
  useSearchFinalContractMutation,
  useSearchInitialContractMutation,
  useSearchParticipantsMutation
} from "./hooks";
import {getCreativeGroupV2Item} from "api/creative_group_v2_item";

export type TDefaultValuesContractEditor = {
  self_ad_contract?: boolean;
  self_ad_participant?: ID | null;
  client_initial?: ID | null;
  initial_contract?: ID | null;
  client?: ID | null;
  contractor?: ID | null;
  final_contract?: ID | null;
};

type Props = {
  isOpen: boolean;
  toggle: () => void;
  groupId: ID;
  onSubmit: (
    {
      // eslint-disable-next-line no-unused-vars
      initial_contract,
      // eslint-disable-next-line no-unused-vars
      final_contract
    }: {
      initial_contract: ID;
      final_contract: ID;
    },
    _initialContractInfo?: Contract,
    _finalContractInfo?: Contract
  ) => void;
};

const ChangeContractModal = ({ isOpen, toggle, groupId, onSubmit: onSubmitExternal }: Props) => {
  const [startValues, setStartValues] = useState<TDefaultValuesContractEditor>();

  const {
    control,
    watch,
    setValue,
    reset,
    formState: { errors },
    handleSubmit,
    clearErrors
  } = useForm({
    defaultValues: {} as TDefaultValuesContractEditor
  });

  const { isLoading, error } = useQuery<APIData<CreativeGroupV2>, Error>({
    queryKey: ["cg_item", groupId],
    queryFn: () => getCreativeGroupV2Item(groupId),
    refetchOnWindowFocus: false,
    onSuccess: ({ data, status }) => {
      if (status) {
        const finalContract = data.extra_fields?.final_contract;
        const initialContract = data.extra_fields?.initial_contract;

        const newStartValues: TDefaultValuesContractEditor = {};

        if (finalContract) {
          newStartValues.client = finalContract.client?.id;
          newStartValues.contractor = finalContract.contractor?.id;
          newStartValues.final_contract = finalContract.id;
        }

        if (initialContract) {
          newStartValues.client_initial = initialContract.client?.id;
          newStartValues.initial_contract = initialContract.id;
        }

        reset(newStartValues);
        setStartValues(newStartValues);
      }
    }
  });

  const onClose = () => {
    toggle();
    reset(startValues);
  };

  // Роль заказчика Рекламодатель
  const [isClientAdvertiser, setIsClientAdvertiser] = useState(false);

  // Контрагент саморекламы (self_ad_client)
  const [searchSelfAdContractsValue, setSearchSelfAdContractsValue] = useState<string | undefined>(
    undefined
  );

  // Заказчик по прямому договору (client)
  const [searchClientParticipantsValue, setSearchClientParticipantsValue] = useState<
    string | undefined
  >(undefined);

  // Исполнитель по прямому договору (contractor)
  const [searchContractorParticipantsValue, setSearchContractorParticipantsValue] = useState<
    string | undefined
  >(undefined);

  // Номер прямого договора (final_contract)
  const [searchContractsValue, setSearchContractsValue] = useState<string | undefined>(undefined);

  // ИНН заказчика по изначальному договору (тип рекламодатель) (client_initial)
  const [searchClientInitialParticipantsValue, setSearchClientInitialParticipantsValue] = useState<
    string | undefined
  >(undefined);

  // Номер изначального договора (initial_contract)
  const [searchInitialContractsValue, setSearchInitialContractsValue] = useState<
    string | undefined
  >(undefined);
  const [initialContractsIds, setInitialContractsIds] =
    useState<Contract["initial_contracts"]>(undefined);

  const {
    mutate: selfAdContractsRequest,
    isLoading: selfAdContractsLoading,
    data: selfAdContractsResponse
  } = useMutation({
    mutationFn: searchMutationContract(searchSelfAdContractsValue, null, {
      filter: {
        contract_type: [4]
      }
    })
  });

  const {
    mutate: clientParticipantsRequest,
    isLoading: clientParticipantsLoading,
    data: clientParticipantsResponse
  } = useSearchParticipantsMutation(searchClientParticipantsValue, watch("client"));

  const {
    mutate: contractorParticipantsRequest,
    isLoading: contractorParticipantsLoading,
    data: contractorParticipantsResponse
  } = useSearchParticipantsMutation(searchContractorParticipantsValue, watch("contractor"));

  const {
    mutate: finalContractsRequest,
    isLoading: finalContractsLoading,
    data: finalContractsResponse
  } = useSearchFinalContractMutation(searchContractsValue, watch("client"), watch("contractor"));

  const {
    mutate: clientInitialParticipantsRequest,
    isLoading: clientInitialParticipantsLoading,
    data: clientInitialParticipantsResponse
  } = useSearchParticipantsMutation(searchClientInitialParticipantsValue, watch("client_initial"));

  const {
    mutate: initialContractsRequest,
    isLoading: initialContractsLoading,
    data: initialContractsResponse
  } = useSearchInitialContractMutation(
    searchInitialContractsValue,
    watch("client_initial"),
    initialContractsIds
  );

  const onSubmit = handleSubmit(
    () => {
      const initial_contract = watch("initial_contract");
      const final_contract = watch("final_contract");
      const initialContractInfo = initialContractsResponse
        ? initialContractsResponse?.data.results.find((c) => c.id === initial_contract)
        : undefined;
      const finalContractInfo = finalContractsResponse
        ? finalContractsResponse?.data.results.find((c) => c.id === final_contract)
        : undefined;
      if (initial_contract && final_contract) {
        const newStartValues: TDefaultValuesContractEditor = {};

        newStartValues.client = watch("client");
        newStartValues.contractor = watch("contractor");
        newStartValues.final_contract = watch("final_contract");
        newStartValues.client_initial = watch("client_initial");
        newStartValues.initial_contract = watch("initial_contract");

        setStartValues(newStartValues);

        onSubmitExternal(
          { initial_contract, final_contract },
          initialContractInfo,
          finalContractInfo
        );
      }
    },
    (err) => {
      console.log(err);
    }
  );

  const onClientOrContractorChange = () => {
    setSearchContractsValue(undefined);
    setValue("final_contract", null);
    clearErrors("final_contract");
    finalContractsRequest();
  };

  if (error)
    return (
      <Modal isOpen={isOpen} toggle={onClose} centered className="p-0 modal-shadow-none">
        <ModalHeader toggle={onClose}>Изменение договора</ModalHeader>
        {error.message}
      </Modal>
    );

  return (
    <Modal isOpen={isOpen} toggle={onClose} centered className="p-0 modal-shadow-none">
      <ModalHeader toggle={onClose}>Изменение договора</ModalHeader>
      <Form
        onSubmit={(event) => {
          event.preventDefault();
          void onSubmit();
        }}
      >
        <ModalBody>
          <CheckBoxField
            name="self_ad_contract"
            title="Креативы к договору саморекламы"
            control={control}
            isChecked={watch("self_ad_contract") as boolean}
            extraAction={() => {
              setValue("client", null);
              setValue("contractor", null);
              setValue("client_initial", null);
              setValue("self_ad_participant", null);
              setValue("final_contract", null);
              setValue("initial_contract", null);
            }}
          />

          {watch("self_ad_contract") ? (
            <PredictiveSearchField
              name="self_ad_participant"
              title="Контрагент саморекламы"
              optionKey={[
                "extra_fields.client.id",
                "extra_fields.client.name",
                "extra_fields.client.inn",
                "id",
                "status"
              ]}
              optionKeyLabels={["ID", "Название", "ИНН", "ID договора", "hidden"]}
              inputValue={searchSelfAdContractsValue}
              setInputValue={setSearchSelfAdContractsValue}
              control={control}
              errors={errors}
              errorText={getErrorText(errors, "self_ad_participant", "Должно быть заполнено.")}
              isLoading={selfAdContractsLoading}
              request={selfAdContractsRequest}
              response={selfAdContractsResponse}
              optionDisableCondition={{ status: 2 }}
              infoText="Нельзя выбрать контрагентов, договор саморекламы которых &lsquo;ЧЕРНОВИК&rsquo;"
              disabled={isLoading}
              clearable
              isRequired
              onChange={(newVal) => {
                if (!newVal) {
                  setValue("client", null);
                  setValue("contractor", null);
                  setValue("client_initial", null);
                  setValue("final_contract", null);
                  setValue("initial_contract", null);
                  setSearchSelfAdContractsValue(undefined);
                  return;
                }

                const currentSelfAdContract = selfAdContractsResponse?.data.results.find(
                  (c) => c.id === newVal
                );

                if (currentSelfAdContract) {
                  setValue("client", currentSelfAdContract.client);
                  setValue("contractor", currentSelfAdContract.contractor);
                  setValue("client_initial", currentSelfAdContract.client);
                  setValue("final_contract", currentSelfAdContract.id);
                  setValue("initial_contract", currentSelfAdContract.id);
                }
              }}
            />
          ) : (
            <>
              <PredictiveSearchField
                name="client"
                title="Заказчик по прямому договору"
                optionKey={["id", "name", "inn", "status"]}
                optionKeyLabels={["ID", "Название", "ИНН", "hidden"]}
                inputValue={searchClientParticipantsValue}
                setInputValue={setSearchClientParticipantsValue}
                control={control}
                errors={errors}
                errorText={getErrorText(errors, "client", "Должно быть заполнено.")}
                isLoading={clientParticipantsLoading}
                request={clientParticipantsRequest}
                response={clientParticipantsResponse}
                optionDisableCondition={{ status: 2 }}
                infoText="Нельзя выбрать контрагентов, статус которых &lsquo;ЧЕРНОВИК&rsquo;"
                disabled={isLoading}
                clearable
                isRequired
                onChange={onClientOrContractorChange}
              />

              <PredictiveSearchField
                name="contractor"
                title="Исполнитель по прямому договору"
                optionKey={["id", "name", "inn", "status"]}
                optionKeyLabels={["ID", "Название", "ИНН", "hidden"]}
                inputValue={searchContractorParticipantsValue}
                setInputValue={setSearchContractorParticipantsValue}
                control={control}
                errors={errors}
                errorText={getErrorText(errors, "contractor", "Должно быть заполнено.")}
                isLoading={contractorParticipantsLoading}
                request={contractorParticipantsRequest}
                response={contractorParticipantsResponse}
                optionDisableCondition={{ status: 2 }}
                infoText="Нельзя выбрать контрагентов, статус которых &lsquo;ЧЕРНОВИК&rsquo;"
                disabled={isLoading}
                clearable
                isRequired
                onChange={onClientOrContractorChange}
              />

              <PredictiveSearchField
                name="final_contract"
                title="Номер прямого договора"
                optionKey={[
                  "id",
                  "contract_number",
                  "extra_fields.client.name",
                  "extra_fields.contractor.name",
                  "status",
                  "contract_type"
                ]}
                optionKeyLabels={[
                  "ID",
                  "Номер контракта",
                  "Заказчик",
                  "Исполнитель",
                  "hidden",
                  "hidden"
                ]}
                inputValue={searchContractsValue}
                setInputValue={setSearchContractsValue}
                control={control}
                errors={errors}
                errorText={getErrorText(errors, "final_contract", "Должно быть заполнено.")}
                isLoading={finalContractsLoading}
                request={finalContractsRequest}
                response={finalContractsResponse}
                optionDisableCondition={[{ status: 2 }, { contract_type: 4 }]}
                infoText="Нельзя выбрать договоры, статус которых &lsquo;ЧЕРНОВИК&rsquo; и/или договоры саморекламы"
                disabled={isLoading}
                clearable
                isRequired
                onChange={(newVal: ID | undefined) => {
                  if (newVal) {
                    const finalContract = finalContractsResponse?.data.results.find(
                      (item) => item?.id === newVal
                    );

                    if (finalContract) {
                      const newClient = finalContract.client;
                      if (newClient) {
                        setValue("client", newClient);
                        clearErrors("client");
                        shortTimeout(clientParticipantsRequest);
                      }

                      const newContractor = finalContract.contractor;
                      if (newContractor) {
                        setValue("contractor", newContractor);
                        clearErrors("contractor");
                        shortTimeout(contractorParticipantsRequest);
                      }

                      const isClientAdvertiserNew = finalContract.client_role === 1;
                      setIsClientAdvertiser(isClientAdvertiserNew);

                      if (isClientAdvertiserNew) {
                        if (newClient) {
                          setValue("client_initial", newClient);
                          clearErrors("client_initial");
                          shortTimeout(clientInitialParticipantsRequest);
                        }

                        setValue("initial_contract", newVal);
                        clearErrors("initial_contract");
                        shortTimeout(initialContractsRequest);
                      } else {
                        setValue("client_initial", null);
                        clearErrors("client_initial");
                        shortTimeout(clientInitialParticipantsRequest);

                        setValue("initial_contract", null);
                        clearErrors("initial_contract");
                        shortTimeout(initialContractsRequest);

                        setInitialContractsIds(finalContract.initial_contracts);
                      }
                    }
                  } else {
                    setSearchContractsValue(undefined);
                  }
                }}
              />

              <PredictiveSearchField
                name="client_initial"
                title="ИНН заказчика по изначальному договору (тип рекламодатель)"
                optionKey={["id", "name", "inn", "status"]}
                optionKeyLabels={["ID", "Название", "ИНН", "hidden"]}
                inputValue={searchClientInitialParticipantsValue}
                setInputValue={setSearchClientInitialParticipantsValue}
                control={control}
                errors={errors}
                errorText={getErrorText(errors, "client_initial", "Должно быть заполнено.")}
                isLoading={clientInitialParticipantsLoading}
                request={clientInitialParticipantsRequest}
                response={clientInitialParticipantsResponse}
                optionDisableCondition={{ status: 2 }}
                infoText="Нельзя выбрать контрагентов, статус которых &lsquo;ЧЕРНОВИК&rsquo;"
                disabled={isLoading || isClientAdvertiser}
                clearable
                isRequired
                onChange={() => {
                  setSearchInitialContractsValue(undefined);
                  setValue("initial_contract", null);
                  clearErrors("initial_contract");
                  shortTimeout(initialContractsRequest);
                }}
              />

              <PredictiveSearchField
                name="initial_contract"
                title="Номер изначального договора"
                optionKey={[
                  "id",
                  "contract_number",
                  "extra_fields.client.name",
                  "extra_fields.contractor.name",
                  "status"
                ]}
                optionKeyLabels={["ID", "Номер контракта", "Заказчик", "Исполнитель", "hidden"]}
                inputValue={searchInitialContractsValue}
                setInputValue={setSearchInitialContractsValue}
                control={control}
                errors={errors}
                errorText={getErrorText(errors, "initial_contract", "Должно быть заполнено.")}
                isLoading={initialContractsLoading}
                request={initialContractsRequest}
                response={initialContractsResponse}
                optionDisableCondition={[{ status: 2 }, { contract_type: 4 }]}
                infoText="Нельзя выбрать договоры, статус которых &lsquo;ЧЕРНОВИК&rsquo;"
                disabled={isLoading || isClientAdvertiser}
                clearable
                isRequired
                onChange={(newVal) => {
                  if (newVal) {
                    const initialContract = initialContractsResponse?.data.results.find(
                      (item) => item?.id === newVal
                    );

                    if (initialContract && initialContract.client) {
                      setValue("client_initial", initialContract.client);
                      clearErrors("client_initial");
                      shortTimeout(clientParticipantsRequest);
                    }
                  } else {
                    setSearchInitialContractsValue(undefined);
                  }
                }}
              />
            </>
          )}
        </ModalBody>
        <ModalFooter className="justify-content-center">
          <Button type="button" color="secondary" onClick={onClose}>
            Отменить
          </Button>
          <Button type="submit" color="primary">
            Сохранить
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default ChangeContractModal;
