import ErrorComponent from "Components/ErrorComponent";
import { format } from "date-fns";
import { CreativeGroupShortV2 } from "models";
import { memo, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import { StateProps } from "reducers";
import {
  setCloseModalDestination,
  setCloseModalNeeded,
  setCloseModalOpen
} from "reducers/CloseModalSettings";
import { AttributesDropdown } from "ui/AttributesDropdown";
import useIsOpenControl from "utils/useIsOpenControl";
import ChangeContractModal from "../ChangeContractModal";
import ChangeGroupModal from "../ChangeGroupModal";
import CreativeAttributesBody from "./components/attributes";
import CreativeForm from "./components/form";
import editCreativeFunction from "./helpers/editCreativeFunction";
import { useDeleteCreative } from "./hooks/useDeleteCreative";
import { useEditCreative } from "./hooks/useEditCreative";
import { useLoadCreative } from "./hooks/useLoadCreative";
import { TCreativeAttributes, TCreativeEditFormProps, TDefaultValuesCreativeEditor } from "./types";

const CreativeEditForm = memo(function CEF({ id }: TCreativeEditFormProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const creativeLastSearch = useSelector((state: StateProps) => state.LastSearchParams.creative);
  const listingLink = `/creative/${creativeLastSearch}`;
  const closeModalIsNeeded = useSelector((state: StateProps) => state.CloseModalSettings.isNeeded);
  const [isDraft, setIsDraft] = useState(false);
  const [attributes, setAttributes] = useState<TCreativeAttributes>("loading");
  const [groupInfo, setGroupInfo] = useState<CreativeGroupShortV2>();

  const { isOpen: isChangeContractModalOpen, toggle: toggleChangeContractModal } =
    useIsOpenControl();
  const { isOpen: isChangeGroupModalOpen, toggle: toggleChangeGroupModal } = useIsOpenControl();

  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
    setValue,
    setError,
    clearErrors
  } = useForm({
    defaultValues: {} as TDefaultValuesCreativeEditor
  });

  const { creativeLoading, creativeError, creative, creativeRefetch, groupId, isContractEditable } =
    useLoadCreative({ id, reset, setIsDraft, setAttributes, setGroupInfo });
  const edit = useEditCreative({ id, listingLink, navigate, setError });
  const onCreativeRemove = useDeleteCreative({ id, listingLink, navigate });

  const onClose = () => {
    if (closeModalIsNeeded) {
      dispatch(setCloseModalOpen());
      dispatch(setCloseModalDestination(listingLink));
    } else {
      navigate(listingLink);
    }
  };

  const onFormChange = () => (!closeModalIsNeeded ? dispatch(setCloseModalNeeded()) : null);

  const onSubmit = handleSubmit(
    () => editCreativeFunction({ id, isDraft, watch, creative, groupInfo, edit }),
    (err) => {
      console.log(err);
    }
  );

  if (creativeError)
    return (
      <ErrorComponent
        error={creativeError?.message || "Не указана Форма распространения"}
        status={creativeError.response?.status}
        isEmbedded
      />
    );

  const creativeTitle = creative?.data.title || `[${id}]`;
  const buttonsDisabled = watch("attachments")
    ?.map(({ fileState }) => fileState)
    .includes("LOADING");

  return (
    <>
      <h1>Редактирование креатива {creativeTitle}</h1>

      <AttributesDropdown title="Атрибуты креатива">
        <CreativeAttributesBody
          attributes={attributes}
          toggleChangeContractModal={toggleChangeContractModal}
          toggleChangeGroupModal={toggleChangeGroupModal}
          isContractEditable={isContractEditable}
        />
      </AttributesDropdown>

      <CreativeForm
        control={control}
        errors={errors}
        onSubmit={onSubmit}
        onFormChange={onFormChange}
        watch={watch}
        creativeLoading={creativeLoading}
        setValue={setValue}
        clearErrors={clearErrors}
        attributes={attributes}
        isDraft={isDraft}
        creativeId={id}
        buttons={
          <div className="d-flex flex-column flex-md-row justify-content-between gap-3 mt-3">
            <div className="d-flex gap-3">
              {isDraft ? (
                <>
                  <Button
                    type="button"
                    color="danger"
                    value="delete"
                    onClick={() => onCreativeRemove(id)}
                    disabled={buttonsDisabled}
                  >
                    Удалить
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    outline
                    value={2}
                    onClick={() => setIsDraft(true)}
                    disabled={buttonsDisabled}
                  >
                    Сохранить черновик
                  </Button>
                </>
              ) : null}
            </div>
            <div className="d-flex gap-3">
              <Button
                type="button"
                color="primary"
                outline
                onClick={onClose}
                disabled={buttonsDisabled}
              >
                Закрыть
              </Button>
              <Button
                type="submit"
                color="danger"
                value={1}
                onClick={() => setIsDraft(false)}
                disabled={buttonsDisabled}
              >
                Отправить в ЕРИР
              </Button>
            </div>
          </div>
        }
      />

      {groupId ? (
        <ChangeContractModal
          isOpen={isChangeContractModalOpen}
          toggle={toggleChangeContractModal}
          groupId={groupId}
          onSubmit={({ initial_contract, final_contract }, initialContractInfo) => {
            setGroupInfo((prev) =>
              prev
                ? {
                    ...prev,
                    initial_contract,
                    final_contract
                  }
                : undefined
            );
            toggleChangeContractModal();
            onFormChange();

            let title = initialContractInfo?.contract_number || `[${initial_contract}]`;
            if (initialContractInfo?.contract_date)
              title += ` от ${format(new Date(initialContractInfo.contract_date), "dd.MM.yyyy")}`;

            setAttributes((prev) =>
              prev !== "loading" && prev !== "error"
                ? {
                    ...prev,
                    initial_contract: { id: initial_contract, title }
                  }
                : prev
            );
          }}
        />
      ) : null}

      <ChangeGroupModal
        isOpen={isChangeGroupModalOpen}
        toggle={toggleChangeGroupModal}
        creativeTitle={creativeTitle}
        isDraft={isDraft}
        creativeId={id}
        groupId={groupId}
        refetch={creativeRefetch}
      />
    </>
  );
});

export default CreativeEditForm;
