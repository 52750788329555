import { IMAGE_EXTENSIONS, SOUND_EXTENSIONS, VIDEO_EXTENSIONS } from "constants/FileExtensions";
import { ID, MediaDataV2 } from "models";
import getFileExtensionFromPath from "utils/getFileExtensionFromPath";

const Body = ({
                item,
                creativeId,
                onLoad
              }: {
  item: MediaDataV2;
  creativeId?: ID;
  onLoad: () => void;
}) => {
  if (item) {
    const extension = getFileExtensionFromPath(item.file_path || "");
    console.log('extension:', extension, item);
    if (extension && creativeId) {
      const src = `/api/v1/creative/${creativeId}/media/${item.id}/`;
      if (IMAGE_EXTENSIONS.includes(extension))
        return (
          <img
            src={src}
            alt={item.file_path}
            style={{ objectFit: "contain", height: "100%", width: "100%" }}
            onLoad={onLoad}
          />
        );

      if (VIDEO_EXTENSIONS.includes(extension)) {
        onLoad();
        return (
          <video controls style={{ objectFit: "contain", height: "100%", width: "100%" }}>
            <source src={src} />
          </video>
        );
      }

      if (SOUND_EXTENSIONS.includes(extension)) {
        onLoad();
        return (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "100%" }}
          >
            <audio controls src={src} style={{ width: "100%" }} />
          </div>
        );
      }
    }
  }

  onLoad();

  return (
    <div className="d-flex justify-content-center align-items-center" style={{ height: "100%" }}>
      <p>Предпросмотр недоступен</p>
    </div>
  );
};

export default Body;
