import { getContract } from "api/contract_item";
import { getCreativeV2Item } from "api/creative_v2_item";
import { APIData } from "api/types";
import { AxiosError } from "axios";
import { format } from "date-fns";
import { CreativeGroupShortV2, CreativeV2, ID } from "models";
import { Dispatch, SetStateAction, useState } from "react";
import { UseFormReset } from "react-hook-form";
import { useQuery } from "react-query";
import { TAttachmentType, TFilesStates } from "types/AttachmentsTypes";
import { v4 as uuidv4 } from "uuid";
import { TCreativeAttributes, TDefaultValuesCreativeEditor } from "../types";

export const useLoadCreative = ({
  id,
  reset,
  setIsDraft,
  setAttributes,
  setGroupInfo
}: {
  id: ID;
  reset: UseFormReset<TDefaultValuesCreativeEditor>;
  setIsDraft: Dispatch<SetStateAction<boolean>>;
  setAttributes: Dispatch<SetStateAction<TCreativeAttributes>>;
  setGroupInfo: Dispatch<SetStateAction<CreativeGroupShortV2 | undefined>>;
}) => {
  const [groupId, setGroupId] = useState<ID>();
  // TODO: CreativeSerializerWithExtraFields -> extra_fields -> groups не хватает creatives
  // const [isContractEditable, setIsContractEditable] = useState(true);

  const {
    isLoading: creativeLoading,
    error: creativeError,
    data: creative,
    refetch: creativeRefetch
  } = useQuery<APIData<CreativeV2>, AxiosError>({
    queryKey: ["creative_item", id],
    queryFn: () => getCreativeV2Item(id),
    retry: (failureCount, error) => {
      if (error.response?.status === 404) {
        return false;
      }
      return failureCount < 3;
    },
    refetchOnWindowFocus: false,
    onSuccess: ({ status, data: creative }) => {
      if (status && creative) {
        const attachmentsMedia =
          creative.medias
            ?.map((item) => ({
              ...item,
              type: "file" as TAttachmentType,
              fileState: "SUCCESS" as TFilesStates,
              tempId: uuidv4()
            }))
            .sort((a, b) => {
              if (a.id && b.id) return +a.id - +b.id;
              return -1;
            }) || [];

        const attachmentsText =
          creative.texts
            ?.map((item) => ({
              ...item,
              type: "text" as TAttachmentType,
              tempId: uuidv4()
            }))
            .sort((a, b) => {
              if (a.id && b.id) return +a.id - +b.id;
              return -1;
            }) || [];

        reset({
          advertiser_urls: creative.advertiser_urls?.map(({ advertiser_url }) => ({
            advertiser_url
          })),
          media_description: "",
          texts: creative.texts,
          media: creative.medias,
          group_id: creative.group as ID,
          no_urls: !creative.advertiser_urls || creative.advertiser_urls.length < 1,
          attachments: [...attachmentsMedia, ...attachmentsText]
        });
        setIsDraft(!!creative.status && +creative.status === 2);

        const initialContractId = creative.extra_fields?.group?.initial_contract;
        const finalContractId = creative.extra_fields?.group?.final_contract;

        let peculiarities = "-";
        if (creative.extra_fields?.group?.is_native) peculiarities = "Нативная";
        if (creative.extra_fields?.group?.is_social) peculiarities = "Социальная";

        // Расчет атрибутов

        const newAttributes: TCreativeAttributes = {
          initial_contract: {
            id: initialContractId
          },
          final_contract: {
            id: finalContractId
          },
          group: {
            id: creative.group as ID,
            label: creative.extra_fields?.group?.label
          },
          marker: creative.marker,
          advertisement_form: creative.extra_fields?.group?.advertisement_form
            ? +creative.extra_fields.group.advertisement_form
            : undefined,
          advertisement_type: creative.extra_fields?.group?.advertisement_type
            ? +creative.extra_fields.group.advertisement_type
            : undefined,
          start_date: creative.extra_fields?.group?.start_date,
          end_date: creative.extra_fields?.group?.end_date,
          peculiarities,
          eacs: creative.extra_fields?.group?.eacs,
          target_audience: creative.extra_fields?.group?.target_audience,
          description: creative.extra_fields?.group?.description,
          status: creative.status ? +creative.status : undefined,
          sync_status: creative.extra_fields?.sync_status
        };

        if (initialContractId || finalContractId) {
          Promise.allSettled([getContract(initialContractId), getContract(finalContractId)])
            .then(([ic, fc]) => {
              if (ic.status === "fulfilled" && ic.value?.data) {
                const contract = ic.value.data;

                let title = contract.contract_number || `[${contract.id}]`;
                if (contract.contract_date)
                  title += ` от ${format(new Date(contract.contract_date), "dd.MM.yyyy")}`;

                newAttributes.initial_contract!.title = title;
              }

              if (fc.status === "fulfilled" && fc.value?.data) {
                const contract = fc.value.data;

                let title = contract.contract_number || `[${contract.id}]`;
                if (contract.contract_date)
                  title += ` от ${format(new Date(contract.contract_date), "dd.MM.yyyy")}`;

                newAttributes.final_contract!.title = title;
              }
            })
            .catch(() => {})
            .finally(() => setAttributes(newAttributes));
        } else {
          setAttributes(newAttributes);
        }

        setGroupId(creative.group as ID);

        const groupInfo = creative.extra_fields?.group;
        setGroupInfo(groupInfo);

        // const creatives = groupInfo?.creatives;

        // if (creatives && creatives.length) {
        //   let isAnyCreativeSync = false;
        //   let i = 0;
        //   do {
        //     isAnyCreativeSync = creatives[i].is_statistics_sent || false;
        //     i++;
        //   } while (!isAnyCreativeSync && i < creatives.length);
        //   setIsContractEditable(!isAnyCreativeSync && creative.status === 2);
        // }
      }
    }
  });

  return {
    creativeLoading,
    creativeError,
    creative,
    creativeRefetch,
    groupId,
    isContractEditable: true
  };
};
