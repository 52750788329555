import EACSOptions from "constants/EACSOptions";
import creative_form from "dictionaries/creative_form.json";
import creative_type from "dictionaries/creative_type.json";
import { FC, ReactElement, useState } from "react";
import {
  Control,
  FieldErrors,
  UseFormClearErrors,
  UseFormSetValue,
  UseFormWatch
} from "react-hook-form/dist/types";
import { useMutation } from "react-query";
import {
  CheckBoxField,
  DatePickerField,
  InputField,
  PredictiveSearchField,
  SelectField,
  TextAreaField
} from "ui";
import createOptions from "utils/createOptions";
import getErrorText from "utils/getErrorText";
import getIsEacsRequired from "utils/getIsEacsRequired";
import { ICreativeAddForm } from "../../../types";
import {getCreativeGroupV2Info} from "api/creative_group_v2";
import {NEW_CreativeGroupAdvertismentForm, NEW_CreativeGroupAdvertismentType} from "../../../../../models";

interface Props {
  watch: UseFormWatch<ICreativeAddForm>;
  control: Control<ICreativeAddForm, any>;
  setValue: UseFormSetValue<ICreativeAddForm>;
  clearErrors: UseFormClearErrors<ICreativeAddForm>;
  errors: FieldErrors<ICreativeAddForm>;
  isDraft?: boolean;
  onAdvertismentFormChange: (_newForm: string | number | (string | number)[] | undefined) => void;
}

export const CreativeFormSecondStep: FC<Props> = ({
  watch,
  control,
  setValue,
  clearErrors,
  errors,
  onAdvertismentFormChange
}): ReactElement => {
  const initialContract = watch("initial_contract");
  const finalContract = watch("final_contract");
  const isNewGroup = !!watch("new_group");
  const isEacsRequired = getIsEacsRequired(watch("final_contract_client_legal_form"));
  const isStartOrEndDateSet = !!watch("start_date") || !!watch("end_date");

  const everythingDisabled = !initialContract || !finalContract;
  const filterByContracts = {
    initial_contract: initialContract ? [initialContract] : undefined,
    final_contract: finalContract ? [finalContract] : undefined
  };

  const creativeAdvertisementFormsOptions = createOptions(creative_form);
  const creativeAdvertisementsTypesOptions = createOptions(creative_type);

  const [searchCreativeGroup, setSearchCreativeGroup] = useState<string | undefined>("");

  const {
    mutate: creativeGroupRequest,
    isLoading: creativeGroupLoading,
    data: creativeGroupResponse
  } = useMutation({
    mutationFn: () => {
      const currentGroupId = watch("group_id");
      return getCreativeGroupV2Info(
        searchCreativeGroup
          ? {
              search: searchCreativeGroup,
              filter: filterByContracts
            }
          : {
              filter: {
                ...filterByContracts,
                id: currentGroupId ? [currentGroupId] : undefined
              }
            }
      );
    }
  });

  return (
    <>
      <p className="fs-4">Шаг 2 из 3: Создание группы креативов</p>

      <div className="row">
        <div className="col">
          <CheckBoxField
            name="new_group"
            title="Новая группа"
            control={control}
            isChecked={isNewGroup}
            extraAction={() => {
              setValue("group_id", undefined);
              setValue("label", undefined);
              setValue("advertisement_type", undefined);
              setValue("advertisement_form", undefined);
              onAdvertismentFormChange(undefined);
              setValue("start_date", undefined);
              setValue("end_date", undefined);
              setValue("peculiarities", undefined);
              setValue("eacs", undefined);
              setValue("target_audience", undefined);
              setValue("description", undefined);
              clearErrors([
                "group_id",
                "label",
                "advertisement_type",
                "advertisement_form",
                "start_date",
                "end_date",
                "peculiarities",
                "eacs",
                "target_audience",
                "description"
              ]);
            }}
            disabled={everythingDisabled}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <PredictiveSearchField
            name="group_id"
            title="Список доступных групп креативов"
            optionKey={["id", "label", "initial_contract", "final_contract"]}
            optionKeyLabels={["ID", "Название", "hidden", "hidden"]}
            inputValue={searchCreativeGroup}
            setInputValue={setSearchCreativeGroup}
            control={control}
            errors={errors}
            errorText={getErrorText(errors, "group_id", "Должно быть заполнено.")}
            isLoading={creativeGroupLoading}
            request={creativeGroupRequest}
            response={creativeGroupResponse}
            clearable
            onChange={(newVal) => {
              const selectedGroup =
                newVal && creativeGroupResponse?.status
                  ? creativeGroupResponse?.data.results.find((item) => item.id === newVal)
                  : undefined;

              setValue("group_id", selectedGroup?.id || undefined);
              setValue("label", selectedGroup?.label || undefined);
              setValue("advertisement_type", Number(selectedGroup?.advertisement_type) as NEW_CreativeGroupAdvertismentType || undefined);
              setValue("advertisement_form", Number(selectedGroup?.advertisement_form) as NEW_CreativeGroupAdvertismentForm || undefined);
              onAdvertismentFormChange(selectedGroup?.advertisement_form || undefined);
              setValue("start_date", selectedGroup?.start_date || undefined);
              setValue("end_date", selectedGroup?.end_date || undefined);
              setValue(
                "peculiarities",
                selectedGroup?.is_native
                  ? "is_native"
                  : selectedGroup?.is_social
                    ? "is_social"
                    : undefined
              );
              setValue("eacs", selectedGroup?.eacs || undefined);
              setValue("target_audience", selectedGroup?.target_audience || undefined);
              setValue("description", selectedGroup?.description || undefined);
            }}
            disabled={everythingDisabled || isNewGroup}
            isRequired={!isNewGroup}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <InputField
            name="label"
            title="Наименование группы"
            control={control}
            errors={errors}
            errorText={getErrorText(errors, "label", "Должно быть заполнено.")}
            disabled={!isNewGroup}
            isRequired={isNewGroup}
            maxLength={2000}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <SelectField
            name="advertisement_type"
            title="Тип рекламной кампании"
            control={control}
            errors={errors}
            errorText={getErrorText(errors, "advertisement_type", "Должно быть заполнено.")}
            options={creativeAdvertisementsTypesOptions}
            disabled={everythingDisabled || !isNewGroup}
            isRequired={isNewGroup}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <SelectField
            name="advertisement_form"
            title="Форма распространения"
            control={control}
            errors={errors}
            errorText={getErrorText(errors, "advertisement_form", "Должно быть заполнено.")}
            options={creativeAdvertisementFormsOptions}
            onChange={onAdvertismentFormChange}
            disabled={everythingDisabled || !isNewGroup}
            isRequired={isNewGroup}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <DatePickerField
            name="start_date"
            title="Дата начала периода размещения"
            control={control}
            errors={errors}
            errorText={getErrorText(errors, "start_date", "Должно быть заполнено.")}
            returnFormat="yyyy-MM-dd"
            disabled={everythingDisabled || !isNewGroup}
            isRequired={isStartOrEndDateSet && isNewGroup}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <DatePickerField
            name="end_date"
            title="Дата окончания периода размещения"
            control={control}
            errors={errors}
            errorText={getErrorText(errors, "end_date", "Должно быть заполнено.")}
            returnFormat="yyyy-MM-dd"
            disabled={everythingDisabled || !isNewGroup}
            isRequired={isStartOrEndDateSet && isNewGroup}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <SelectField
            name="peculiarities"
            title="Особенности рекламной кампании"
            control={control}
            errors={errors}
            options={[
              { value: "is_social", label: "Социальная" },
              { value: "is_native", label: "Нативная" }
            ]}
            disabled={everythingDisabled || !isNewGroup}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <SelectField
            name="eacs"
            title="Коды ОКВЭД"
            control={control}
            errors={errors}
            errorText={getErrorText(errors, "eacs", "Должно быть заполнено.")}
            options={EACSOptions}
            chunkSize={10}
            isMulti
            infoText="Коды ОКВЭД рекламируемого товара, выберите один или несколько из справочника."
            disabled={everythingDisabled || !isNewGroup}
            isRequired={isEacsRequired && isNewGroup}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <InputField
            name="target_audience"
            title="Параметры целевой аудитории"
            control={control}
            errors={errors}
            disabled={everythingDisabled || !isNewGroup}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <TextAreaField
            name="description"
            title="Общее описание объекта рекламирования"
            control={control}
            errors={errors}
            errorText={getErrorText(errors, "description", "Должно быть заполнено.")}
            disabled={everythingDisabled || !isNewGroup}
            infoText={
              <>
                <p>Укажите:</p>
                <ul>
                  <li>Бренд (или несколько брендов) рекламируемых товаров или услуг;</li>
                  <li>Вид товара / услуги;</li>
                  <li>Дополнительную информацию.</li>
                </ul>
                <p>Примеры:</p>
                <ul>
                  <li>Tide. Стиральный порошок. Годовой запас в подарок.</li>
                  <li>
                    Отель «Ромашка». Бронирование номеров.Гостиница, отель в Подмосковье на
                    выходные.
                  </li>
                </ul>
              </>
            }
            maxLength={1000}
            isRequired={isNewGroup}
          />
        </div>
      </div>
    </>
  );
};
