import { getCreativeGroupV2Item } from "api/creative_group_v2_item";
import { APIData } from "api/types";
import { CreativeGroupV2, ID } from "models";
import { Dispatch, SetStateAction } from "react";
import { UseFormReset } from "react-hook-form";
import { useQuery } from "react-query";
import { TContractInfo } from "../components/contractNumber";
import { IDefaultValuesCreativeGroupEditor } from "../types";

export const useLoadCreativeGroup = ({
  id,
  reset,
  setContractInfo
}: {
  id: ID;
  reset: UseFormReset<IDefaultValuesCreativeGroupEditor>;
  setContractInfo: Dispatch<SetStateAction<TContractInfo | undefined>>;
}) => {
  const {
    isLoading,
    error,
    data: creativeGroup
  } = useQuery<APIData<CreativeGroupV2>, Error>({
    queryKey: ["cg_item", id],
    queryFn: () => getCreativeGroupV2Item(id),
    onSuccess: ({ data }) => {
      if (data) {
        reset({
          label: data.label,
          advertisement_type: data.advertisement_type ? +data.advertisement_type : undefined,
          advertisement_form: data.advertisement_form ? +data.advertisement_form : undefined,
          start_date: data.start_date,
          end_date: data.end_date,
          eacs: data.eacs,
          target_audience: data.target_audience,
          description: data.description,
          initial_contract: data.initial_contract,
          final_contract: data.final_contract,
          peculiarities: data.is_native ? "is_native" : data.is_social ? "is_social" : undefined,
          final_contract_client_legal_form: data.extra_fields?.final_contract?.client?.legal_form
        });

        const initial_contract = creativeGroup?.data.extra_fields?.initial_contract;
        if (initial_contract?.id) {
          setContractInfo({
            id: initial_contract.id,
            number: initial_contract.contract_number,
            clientId: initial_contract.client?.id,
            clientName: initial_contract.client?.name,
            contractorId: initial_contract.contractor?.id,
            contractorName: initial_contract.contractor?.name
          });
        }

        // const creatives = creativeGroup.data.extra_fields?.creatives;
        // if (creatives && creatives.length) {
        //   let isAnyCreativeSync = false;
        //   let i = 0;
        //   do {
        //     isAnyCreativeSync = creatives[i].is_statistics_sent || false;
        //     i++;
        //   } while (!isAnyCreativeSync && i < creatives.length);
        //   setIsContractEditable(!isAnyCreativeSync);
        // }
      }
    }
  });

  return {
    isLoading,
    error,
    creativeGroup
  };
};
