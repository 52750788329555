import ChangeGroupModal from "Components/ChangeGroupModal";
import creative_form from "dictionaries/creative_form.json";
import creative_status from "dictionaries/creative_status.json";
import creative_type from "dictionaries/creative_type.json";
import { Contract, CreativeV2, ID } from "models";
import { useState } from "react";
import { FaFile } from "react-icons/fa";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { ListGroupItem } from "reactstrap";
import IExportRelatedProps from "types/IExportRelatedProps";
import ListingCard from "ui/ListingCard";
import ListingCardsWrapper from "ui/ListingCardsWrapper";
import ListingTable from "ui/ListingTable";
import ListingTableRow from "ui/ListingTableRow";
import ParticipantCell from "ui/ParticipantDisplay/ParticipantCell";
import ParticipantInline from "ui/ParticipantDisplay/ParticipantInline";
import StatusCell from "ui/StatusDisplay/StatusCell";
import StatusInline from "ui/StatusDisplay/StatusInline";
import WrappedCell from "ui/WrappedCell";
import getDate from "utils/getDate";
import getTextShortened from "utils/getTextShortened";
import getTitleFromDict from "utils/getTitleFromDict";
import onDeleteMutation from "utils/onDeleteMutation";
import useIsOpenControl from "utils/useIsOpenControl";
import getActions from "./getActions";
import { CreativePreviewModal } from "./preview";
import {deleteCreativeV2} from "api/creative_v2_delete";

interface Props extends IExportRelatedProps {
  creatives: CreativeV2[];
  contracts: Contract[];
  refetch: () => Promise<any>;
}

export const CreativeTable = ({
  creatives,
  contracts,
  exportIds,
  onExportIdCheck,
  pageIds,
  setExportIds,
  refetch
}: Props) => {
  const [currentCreative, setCurrentCreative] = useState<CreativeV2>();
  const [currentGroupInfo, setCurrentGroupInfo] = useState<{
    creativeId?: ID;
    groupId?: ID;
    isDraft?: boolean;
  }>({});

  const navigate = useNavigate();
  const onRowDoubleClick = (id: ID) => navigate(`/creative/${id}`);

  const { isOpen: isCreativeCarouselModalOpen, toggle: toggleCreativeCarouselModal } =
    useIsOpenControl();
  const { isOpen: isChangeGroupModalOpen, toggle: toggleChangeGroupModal } = useIsOpenControl();

  const { mutate: delCreative } = useMutation({
    mutationFn: deleteCreativeV2,
    onSuccess: (response) =>
      onDeleteMutation({
        response,
        refetch,
        successMessage: "Креатив удален"
      })
  });

  const actions = getActions(
    creatives,
    delCreative,
    navigate,
    setCurrentGroupInfo,
    toggleChangeGroupModal
  );

  const onCreativeClick = (selectedId: ID) => {
    setCurrentCreative(creatives.find(({ id }) => id === selectedId));
    toggleCreativeCarouselModal();
  };

  return (
    <>
      <ListingTable
        theadList={[
          "Наименование креатива",
          "Токен креатива",
          "Статус",
          "Рекламодатель",
          "№ изначального договора",
          "Название группы креативов",
          "Период размещения",
          "Заказчик",
          "Исполнитель",
          "№ договора",
          "Тип рекламной компании",
          "Форма рекламной компании",
          "Натив",
          "Социал"
        ]}
        pageIds={pageIds}
        exportIds={exportIds}
        setExportIds={setExportIds}
        tbody={creatives.map((creative: CreativeV2) => {
          const group = creative.extra_fields?.group;

          const initial_contract = contracts.find((c) => c.id === group?.initial_contract);
          const final_contract = contracts.find((c) => c.id === group?.final_contract);

          const creativeTitle = getTextShortened(
            creative.title ||
              creative.medias?.[0]?.file_path?.split("/").at(-1) ||
              creative.texts?.[0]?.text ||
              "",
            20
          );

          const isAttached =
            (creative.medias && creative.medias.length) ||
            (creative.texts && creative.texts.length);

          return (
            <ListingTableRow
              key={creative.id}
              id={creative.id}
              item={creative}
              onRowDoubleClick={() => (creative.id ? onRowDoubleClick(creative.id) : null)}
              onExportIdCheck={onExportIdCheck}
              exportIds={exportIds}
              actions={actions}
              list={
                <>
                  <td style={{ minWidth: "24ch" }}>
                    {creativeTitle}
                    <br />
                    {isAttached && creative.id ? (
                      <FaFile
                        onClick={() => creative.id && onCreativeClick(creative.id)}
                        style={{ cursor: "pointer" }}
                      />
                    ) : null}
                  </td>
                  <td>{creative.marker}</td>
                  <StatusCell data={creative} dictionary={creative_status} />
                  {initial_contract ? (
                    <>
                      <ParticipantCell
                        id={`finalContractClient${creative.id}`}
                        name={initial_contract?.extra_fields?.client?.name}
                        inn={initial_contract?.extra_fields?.client?.inn}
                        ord={initial_contract?.extra_fields?.client?.ord_accounts}
                      />
                      <td>{initial_contract?.contract_number ?? ""}</td>
                    </>
                  ) : (
                    <>
                      <ParticipantCell
                        id={`client${creative.id}`}
                        name={final_contract?.extra_fields?.client?.name}
                        inn={final_contract?.extra_fields?.client?.inn}
                        ord={final_contract?.extra_fields?.client?.ord_accounts}
                      />
                      <td>{final_contract?.contract_number ?? ""}</td>
                    </>
                  )}
                  <td>
                    {group ? <a href={`#/creative/?group=${group.id}`}>{group.label}</a> : null}
                  </td>
                  <td>
                    {getDate(group?.start_date)} {group?.start_date ? "-" : ""}
                    {group?.end_date ? <br /> : <></>}
                    {getDate(group?.end_date)}
                  </td>
                  <ParticipantCell
                    id={`client${creative.id}`}
                    name={final_contract?.extra_fields?.client?.name}
                    inn={final_contract?.extra_fields?.client?.inn}
                    ord={final_contract?.extra_fields?.client?.ord_accounts}
                  />
                  <ParticipantCell
                    id={`contractor${creative.id}`}
                    name={final_contract?.extra_fields?.contractor?.name}
                    inn={final_contract?.extra_fields?.contractor?.inn}
                    ord={final_contract?.extra_fields?.contractor?.ord_accounts}
                  />
                  <td>{final_contract?.contract_number ?? ""}</td>
                  <WrappedCell
                    text={getTitleFromDict(group?.advertisement_type, creative_type)}
                    maxLength={25}
                  />
                  <WrappedCell
                    text={getTitleFromDict(group?.advertisement_form, creative_form)}
                    maxLength={25}
                  />
                  <td>{group?.is_native ? "да" : "нет"}</td>
                  <td>{group?.is_social ? "да" : "нет"}</td>
                </>
              }
            />
          );
        })}
      />
      <ListingCardsWrapper>
        {creatives.map((creative: CreativeV2) => {
          const group = creative.extra_fields?.group;

          const initial_contract = contracts.find((c) => c.id === group?.initial_contract);
          const final_contract = contracts.find((c) => c.id === group?.final_contract);

          const creativeTitle = getTextShortened(
            creative.title ||
              creative.medias?.[0]?.file_path?.split("/").at(-1) ||
              creative.texts?.[0]?.text ||
              "",
            20
          );

          const isAttached =
            (creative.medias && creative.medias.length) ||
            (creative.texts && creative.texts.length);

          return (
            <ListingCard
              key={creative.id}
              id={creative.id}
              item={creative}
              header={
                <>
                  {creative.id} | {creativeTitle}{" "}
                  {isAttached ? (
                    <FaFile
                      onClick={() => creative.id && onCreativeClick(creative.id)}
                      style={{ cursor: "pointer" }}
                    />
                  ) : null}
                </>
              }
              onExportIdCheck={onExportIdCheck}
              exportIds={exportIds}
              onHeaderClick={() => onCreativeClick(creative.id ?? 0)}
              actions={actions}
              list={
                <>
                  <ListGroupItem>Токен креатива: {creative.marker || ""}</ListGroupItem>
                  <ListGroupItem>
                    Статус: <StatusInline data={creative} dictionary={creative_status} />
                  </ListGroupItem>
                  <ListGroupItem>
                    Рекламодатель:{" "}
                    <ParticipantInline
                      id={`finalContractClient${creative.id}`}
                      name={initial_contract?.extra_fields?.client?.name}
                      inn={initial_contract?.extra_fields?.client?.inn}
                      ord={initial_contract?.extra_fields?.client?.ord_accounts}
                    />
                  </ListGroupItem>
                  <ListGroupItem>
                    № изначального договора: {initial_contract?.contract_number ?? ""}
                  </ListGroupItem>
                  <ListGroupItem>
                    Название группы креативов:{" "}
                    {group ? <a href={`#/creative/?group=${group.id}`}>{group.label}</a> : null}
                  </ListGroupItem>
                  <ListGroupItem>
                    Период размещения: {getDate(group?.start_date)}
                    {group?.end_date ? `-${getDate(group?.end_date)}` : ""}
                  </ListGroupItem>
                  <ListGroupItem>
                    Заказчик:{" "}
                    <ParticipantInline
                      id={`client${creative.id}`}
                      name={final_contract?.extra_fields?.client?.name}
                      inn={final_contract?.extra_fields?.client?.inn}
                      ord={final_contract?.extra_fields?.client?.ord_accounts}
                    />
                  </ListGroupItem>
                  <ListGroupItem>
                    Исполнитель:{" "}
                    <ParticipantInline
                      id={`contractor${creative.id}`}
                      name={final_contract?.extra_fields?.contractor?.name}
                      inn={final_contract?.extra_fields?.contractor?.inn}
                      ord={final_contract?.extra_fields?.contractor?.ord_accounts}
                    />
                  </ListGroupItem>
                  <ListGroupItem>№ договора: {final_contract?.contract_number ?? ""}</ListGroupItem>
                  <ListGroupItem>
                    Тип рекламной компании:{" "}
                    {getTitleFromDict(group?.advertisement_type, creative_type)}
                  </ListGroupItem>
                  <ListGroupItem>
                    Форма рекламной компании:{" "}
                    {getTitleFromDict(group?.advertisement_form, creative_form)}
                  </ListGroupItem>
                  <ListGroupItem className="d-flex p-0">
                    <div className="py-2 py-2 px-3 w-50">
                      Натив: {group?.is_native ? "да" : "нет"}
                    </div>
                    <div className="border-start border-1 py-2 px-3">
                      Социал: {group?.is_social ? "да" : "нет"}
                    </div>
                  </ListGroupItem>
                </>
              }
            />
          );
        })}
      </ListingCardsWrapper>

      <CreativePreviewModal
        creative={currentCreative}
        isOpen={isCreativeCarouselModalOpen}
        toggle={toggleCreativeCarouselModal}
      />

      <ChangeGroupModal
        isOpen={isChangeGroupModalOpen}
        toggle={toggleChangeGroupModal}
        refetch={refetch}
        creativeTitle=""
        {...currentGroupInfo}
      />
    </>
  );
};
